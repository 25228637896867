import cn from "classnames";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { getButtonClassName } from "web-ui";

import AppLinks from "components/common/AppLinks";
import Award from "components/common/Award";
import FooterLinks from "components/common/FooterLinks";
import Testimonial from "components/common/Testimonial";
import imageEfficacy from "images/abc-efficacy.svg";
import imageArrowDown from "images/arrow-down.svg";
import imageFofoPeek from "images/fofo-peek.svg";
import imageHillBottom from "images/hill-bottom-green.svg";
import imageHillTop from "images/hill-top-green.svg";
import imageFooter from "images/home-footer.svg";
import imageHero from "images/home-hero.svg";
import imageFish from "images/icon-fish.svg";
import imageFiveStars from "images/icon-five-stars.svg";
import imageLetterA from "images/icon-letter-a.svg";
import imagePencil from "images/icon-pencil.svg";
import imageSpeechBubble from "images/icon-speech-bubble.svg";
import imageLearnThroughPlay from "images/learn-through-play.svg";
import imageSectionBottom from "images/section-bottom-green.svg";
import imageSectionTop from "images/section-top-green.svg";
import imageStoriesKidsLove from "images/stories-kids-love.svg";
import { R } from "strings/definitions";
import { Links } from "util/constants";
import { _i, _iText } from "util/i18n";
import track from "util/track";

import styles from "./Home.module.scss";

const TRACKING_PAGE = "home";

interface LessonItem {
  description: TranslatedString;
  image: string;
  title: TranslatedString;
}

const LessonCard = ({
  item: { image, title, description },
}: {
  item: LessonItem;
}) => (
  <div className={styles.card}>
    <img className={styles["card-image"]} src={image} />
    <div className={styles["card-content"]}>
      <div className={styles["card-title"]}>{title}</div>
      <div className={styles["card-description"]}>{description}</div>
    </div>
  </div>
);

const SCREENSHOT_ITEMS = [
  {
    image: imageLearnThroughPlay,
    subtitle: _i(R.A_COMPREHENSIVE_PHONICS_CURRICULUM_THAT_FEELS_LIKE_A_GAME),
    title: _i(R.LEARNING_TO_READ_THROUGH_PLAY),
  },
  {
    image: imageStoriesKidsLove,
    subtitle: _i(R.FUNNY_CHARACTER_DRIVEN_STORIES_THAT_HELP_KIDS),
    title: _i(R.STORIES_KIDS_LOVE),
  },
];

const LESSON_CARD_ITEMS = [
  {
    description: _i(R.LEARN_LETTER_SOUNDS_AND_DECODING_WITH_BITESIZED_LESSONS),
    image: imageFish,
    title: _i(R.PHONICS),
  },
  {
    description: _i(R.PRACTICE_WRITING_LOWERCASE_AND_CAPITAL_LETTERS),
    image: imagePencil,
    title: _i(R.HANDWRITING),
  },
  {
    description: _i(R.LEARN_COMMON_TRICKY_WORDS_LIKE_ONE_AND_WAS),
    image: imageSpeechBubble,
    title: _i(R.SIGHT_WORDS),
  },
  {
    description: _i(R.BUILD_READING_COMPREHENSION_AND_WORD_KNOWLEDGE),
    image: imageLetterA,
    title: _i(R.VOCABULARY),
  },
];

const TESTIMONIALS = [
  _i(R.IM_AMAZED_HOW_MUCH_THIS_APP_HAS_HELPED_MY_SON),
  _i(R.THIS_APP_IS_EXCELLENT),
  _i(R.OUR_7_YEAR_OLD_HAS_STRUGGLED_TO_LEARN_TO_READ),
];

const AWARDS = [
  {
    color: "#1CB0F6",
    text: _iText(R.N2021_WEBBY_AWARD_WINNER),
  },
  {
    color: "#FFC800",
    text: _iText(R.N2020_TIME_BEST_100_INVENTIONS),
  },
  {
    color: "#FF5C5C",
    text: _iText(R.APPLE_APP_STORE_EDITORS_CHOICE),
  },
];

interface Props {
  topbarRef: React.RefObject<HTMLDivElement>;
}

const Home = ({ topbarRef }: Props) => {
  const location = useLocation();

  useEffect(() => {
    track("lit_web_page_load", { page: TRACKING_PAGE });
  }, []);

  const scrollToFirstSection = () => {
    if (topbarRef?.current === null) {
      return;
    }
    const topbarHeight = topbarRef.current.getBoundingClientRect().height;
    const element = document.getElementById("first-section");
    if (!element) {
      return;
    }
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - topbarHeight;
    window.scrollTo({ behavior: "smooth", top: offsetPosition });
  };

  return (
    <div>
      {/* HERO SECTION */}
      <section className={styles["hero-section"]}>
        <div className={styles["hero-content"]}>
          <div className={styles["hero-header"]}>
            <h1 className={styles["title-owl"]}>
              {_i(R.LEARNING_TO_READ_IS_HARD_WE_MADE_IT_FUN)}
            </h1>
            <p className={styles["subtitle-eel"]}>
              {_i(R.DESIGNED_BY_EDUCATION_EXPERTS)}
            </p>
            <AppLinks page={TRACKING_PAGE} />
          </div>
          <img className={styles["hero-image"]} src={imageHero} />
        </div>
        <div className={styles["hill-background"]}>
          <div className={styles["top-border-container"]}>
            <img className={styles["border-image"]} src={imageHillTop} />
          </div>
          <div className={styles["hero-hill-content"]}>
            <div
              className={styles["learn-more"]}
              onClick={scrollToFirstSection}
            >
              {_i(R.LEARN_MORE)}
              <img className={styles["arrow-down-link"]} src={imageArrowDown} />
            </div>
          </div>
          <div className={styles["bottom-border-container"]}>
            <img className={styles["border-image"]} src={imageHillBottom} />
          </div>
        </div>
      </section>
      {/* SCREENSHOTS SECTION */}
      <div id="first-section">
        <section className={styles["white-section"]}>
          <div className={styles["screenshots-content"]}>
            {SCREENSHOT_ITEMS.map((item, index) => (
              <div
                className={
                  styles[
                    `screenshots-feature-${index % 2 === 0 ? "left" : "right"}`
                  ]
                }
                key={index}
              >
                <div className={styles["screenshots-text"]}>
                  <h1 className={styles["title-eel"]}>{item.title}</h1>
                  <p className={styles["subtitle-wolf"]}>{item.subtitle}</p>
                </div>
                <img className={styles["screenshots-image"]} src={item.image} />
              </div>
            ))}
          </div>
        </section>
      </div>
      {/* LESSONS SECTION */}
      <section className={styles["colorful-section"]}>
        <div className={styles["top-border-container"]}>
          <img className={styles["border-image"]} src={imageSectionTop} />
        </div>
        <div className={styles["green-content-container"]}>
          <div className={styles["lessons-content"]}>
            <div className={styles["lessons-text"]}>
              <h1 className={styles.title}>{_i(R.WHAT_KIDS_WILL_LEARN)}</h1>
              <p className={styles.subtitle}>
                {_i(R.OUR_CURRICULUM_IS_BASED_ON_RECOMMENDATIONS)}
              </p>
            </div>
            <div className={styles["lessons-cards"]}>
              {LESSON_CARD_ITEMS.map((item, index) => (
                <LessonCard item={item} key={index} />
              ))}
            </div>
            <Link
              className={cn(
                getButtonClassName({ color: "macaw", variant: "solid" }),
                styles.button,
              )}
              onClick={() => window.scrollTo({ top: 0 })}
              to={`/how-we-teach${location.search}`}
            >
              {_i(R.EXPLORE_HOW_WE_TEACH)}
            </Link>
          </div>
        </div>
        <div className={styles["bottom-border-container"]}>
          <img className={styles["border-image"]} src={imageSectionBottom} />
        </div>
      </section>
      {/* EFFICACY SECTION */}
      <section className={styles["white-section"]}>
        <div className={styles["efficacy-content"]}>
          <div className={styles["efficacy-text"]}>
            <h1 className={styles["title-eel"]}>{_i(R.EFFECTIVE_LEARNING)}</h1>
            <p className={styles["subtitle-wolf"]}>
              {_i(R.RESEARCH_BY_EDUCATION_DEVELOPMENT_CENTER_SHOWS)}
            </p>
            <a
              className={styles["efficacy-link"]}
              href="https://duolingo-papers.s3.amazonaws.com/reports/edc-report-on-duolingo-abc.pdf"
              onClick={() =>
                track("lit_web_click", {
                  page: TRACKING_PAGE,
                  target: "efficacy_report",
                })
              }
              rel="noreferrer"
              target="_blank"
            >
              {_i(R.READ_THE_FULL_REPORT)}
            </a>
          </div>
          <img className={styles["efficacy-image"]} src={imageEfficacy} />
        </div>
      </section>
      {/* TESTIMONIALS SECTION */}
      <section className={styles["colorful-section"]}>
        <div style={{ position: "relative" }}>
          <img className={styles.fofo} src={imageFofoPeek} />
          <div className={styles["top-border-container"]}>
            <img className={styles["border-image"]} src={imageSectionTop} />
          </div>
        </div>
        <div className={styles["green-content-container"]}>
          <div className={styles["testimonials-content"]}>
            <h1 className={styles.title}>{_i(R.WHAT_PARENTS_ARE_SAYING)}</h1>
            <img
              className={styles["testimonials-stars"]}
              src={imageFiveStars}
            />
            <div className={styles["testimonials-cards"]}>
              {TESTIMONIALS.map((text, index) => (
                <Testimonial item={{ quote: text }} key={index} />
              ))}
            </div>
          </div>
        </div>
        <div className={styles["bottom-border-container"]}>
          <img className={styles["border-image"]} src={imageSectionBottom} />
        </div>
      </section>
      {/* AWARDS SECTION */}
      <section className={styles["white-section"]}>
        <div className={styles["awards-content"]}>
          <h1 className={styles.title}>
            {_i(R.HELPING_KIDS_IS_OUR_BIGGEST_REWARD)}
          </h1>
          <p className={styles["subtitle-wolf"]}>
            {_i(R.BUT_THESE_WERE_NICE_TOO)}
          </p>
          <div className={styles.awards}>
            {AWARDS.map((item, index) => (
              <Award item={item} key={index} />
            ))}
          </div>
        </div>
      </section>
      {/* FOOTER SECTION */}
      <section className={styles["footer-section"]}>
        <div className={styles["footer-content"]}>
          <div className={styles["footer-text"]}>
            <h1 className={styles.title}>{_i(R.WERE_ON_A_MISSION)}</h1>
            <p className={styles["subtitle-wolf"]}>
              {_i(R.DEVELOPED_BY_A_TEAM_OF_LEARNING_SCIENTISTS)}
            </p>
            <AppLinks page={TRACKING_PAGE} />
          </div>
          <img className={styles["footer-image"]} src={imageFooter} />
        </div>
        <div className={styles["hill-background"]}>
          <div className={styles["top-border-container"]}>
            <img className={styles["border-image"]} src={imageHillTop} />
          </div>
          <div className={styles.contact}>
            <h1 className={styles["contact-title"]}>{_i(R.CONTACT_US)}</h1>
            <p className={styles["contact-subtitle"]}>
              {_i(R.REACH_OUT_AT, {
                link_class: styles["contact-link"],
                mailto_link: `mailto:${Links.Email}?subject=Duolingo%20ABC%20Feedback`,
                press_kit_link: Links.PressKit,
              })}
            </p>
          </div>
          <div className={styles.links}>
            <div className={styles.spacer} />
            <FooterLinks />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
