import cn from "classnames";
import React, { useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";
import { LoadingDots, getButtonClassName } from "web-ui";

import {
  getAllImages,
  getAllNarrations,
  uploadImages,
  uploadNarrations,
} from "api";
import FileSelector from "components/common/FileSelector";
import Loader from "components/common/Loader";
import TabGroup from "components/common/TabGroup";
import { playAudio } from "util/audio";
import type { Language } from "util/commonTypes";
import { BaseCdnURL } from "util/constants";
import { filterItems } from "util/search";

import styles from "./Assets.module.scss";

type AssetType = "image" | "narration";

const assetTypeInfo = (assetType: AssetType) => {
  switch (assetType) {
    case "image":
      return {
        allowedFileTypes: [".svg"],
        searchPlaceholder: "Search Images",
        uploadTitle: "Upload New Images",
        viewTitle: "Existing Images",
      };
    case "narration":
      return {
        allowedFileTypes: [".mp3"],
        searchPlaceholder: "Search Narrations",
        uploadTitle: "Upload New Narrations",
        viewTitle: "Existing Narrations",
      };
    default:
      throw new Error("Invalid asset type");
  }
};

const FileUploadView = ({
  assetType,
  files,
  isUploading,
  setFiles,
  uploadFiles,
}: {
  assetType: AssetType;
  files: File[];
  isUploading: boolean;
  setFiles: (files: File[]) => void;
  uploadFiles: (assetType: AssetType, files: File[]) => void;
}) => (
  <div className={styles.container}>
    <div className={styles["upload-content"]}>
      <h1 className={styles["title-wolf"]}>
        {assetTypeInfo(assetType).uploadTitle}
      </h1>
      <div className={styles["upload-buttons"]}>
        <FileSelector
          className={styles["upload-button"]}
          fileTypes={assetTypeInfo(assetType).allowedFileTypes}
          multiple={true}
          selectFiles={selectedFiles =>
            selectedFiles && setFiles([...selectedFiles])
          }
        />
        <div
          className={cn(
            styles["upload-button"],
            getButtonClassName({ color: "macaw", variant: "solid" }),
          )}
          onClick={() => uploadFiles(assetType, files)}
        >
          {isUploading ? <LoadingDots type="button" /> : "Upload"}
        </div>
      </div>
      <div className={styles["pending-file-uploads"]}>
        {files.length === 0 ? (
          <p>No files selected</p>
        ) : (
          files.map((file, index) => <p key={index}>{file.name}</p>)
        )}
      </div>
    </div>
  </div>
);

const NarrationItem = ({
  data,
  index,
  style,
}: {
  data: string[];
  index: number;
  style: React.CSSProperties;
}) => (
  <div className={styles["asset-row"]} key={index} style={style}>
    <button
      className={cn(
        getButtonClassName({ color: "macaw", variant: "solid" }),
        styles["play-button"],
      )}
      onClick={() =>
        playAudio(
          `${BaseCdnURL}/narrations/en/${data[index]}?lastmod=${Math.floor(
            Date.now() / 1000,
          )}`,
        )
      }
    >
      ▶
    </button>
    <a
      className={styles.link}
      href={`${BaseCdnURL}/narrations/en${data[index]}`}
    >
      {data[index]}
    </a>
  </div>
);

const ImageItem = ({
  data,
  index,
  style,
}: {
  data: string[];
  index: number;
  style: React.CSSProperties;
}) => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return (
    <div className={styles["asset-row"]} key={index} style={style}>
      <img
        className={styles["image-preview"]}
        src={`${BaseCdnURL}/images/${data[index]}?lastmod=${currentTimestamp}`}
      />
      <a
        className={styles.link}
        href={`${BaseCdnURL}/images/${data[index]}?lastmod=${currentTimestamp}`}
      >
        {data[index]}
      </a>
    </div>
  );
};

const AssetViewer = ({
  assetType,
  currentAssets,
  selectedFiles,
  isUploading,
  query,
  setQuery,
  setFiles,
  uploadFiles,
}: {
  assetType: AssetType;
  currentAssets: string[];
  selectedFiles: File[];
  isUploading: boolean;
  query: string;
  setQuery: (newQuery: string) => void;
  setFiles: (files: File[]) => void;
  uploadFiles: (assetType: AssetType, files: File[]) => void;
}) => {
  const filteredCurrentAssets = filterItems(currentAssets, query);
  return (
    <div>
      <FileUploadView
        assetType={assetType}
        files={selectedFiles}
        isUploading={isUploading}
        setFiles={setFiles}
        uploadFiles={uploadFiles}
      />
      <div className={styles.container}>
        <div className={styles["asset-view-header"]}>
          <h1>{assetTypeInfo(assetType).viewTitle}</h1>
          <input
            className={styles["asset-search"]}
            onChange={e => setQuery(e.target.value)}
            placeholder={assetTypeInfo(assetType).searchPlaceholder}
            value={query}
          />
        </div>
        <List
          className={styles["asset-table"]}
          height={500}
          itemCount={filteredCurrentAssets.length}
          itemData={filteredCurrentAssets}
          itemSize={80}
          width="100%"
        >
          {assetType === "image" ? ImageItem : NarrationItem}
        </List>
      </div>
    </div>
  );
};

const Assets = () => {
  const [images, setImages] = useState<string[]>();
  const [narrations, setNarrations] = useState<string[]>();
  const [language] = useState<Language>("en"); // TODO: Add a dropdown to change this [en, es]
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [narrationFiles, setNarrationFiles] = useState<File[]>([]);
  const [query, setQuery] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    let ignore = false;

    const fetchAndSetData = async () => {
      const [imageList, narrationList] = await Promise.all([
        getAllImages(),
        getAllNarrations(language),
      ]);
      if (!ignore) {
        setImages(imageList);
        setNarrations(narrationList);
      }
    };

    fetchAndSetData();
    return () => {
      ignore = true;
    };
  }, [language, setImages, setNarrations]);

  const uploadFiles = async (assetType: AssetType, files: File[]) => {
    if (files.length === 0) {
      alert("No files selected");
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    files.forEach(file => formData.append(file.name, file));

    if (assetType === "image") {
      const uploadedImages = await uploadImages(formData);
      setImages([
        ...uploadedImages.filter(image => !images?.includes(image)),
        ...(images ?? []),
      ]);
      setImageFiles([]);
    } else {
      const uploadedNarrations = await uploadNarrations(formData, language);
      setNarrations([
        ...uploadedNarrations.filter(
          narration => !narrations?.includes(narration),
        ),
        ...(narrations ?? []),
      ]);
      setNarrationFiles([]);
    }

    setIsUploading(false);
  };

  const items = [
    {
      content: (
        <AssetViewer
          assetType="image"
          currentAssets={images ?? []}
          isUploading={isUploading}
          query={query}
          selectedFiles={imageFiles}
          setFiles={setImageFiles}
          setQuery={setQuery}
          uploadFiles={uploadFiles}
        />
      ),
      label: "Images",
    },
    {
      content: (
        <AssetViewer
          assetType="narration"
          currentAssets={narrations ?? []}
          isUploading={isUploading}
          query={query}
          selectedFiles={narrationFiles}
          setFiles={setNarrationFiles}
          setQuery={setQuery}
          uploadFiles={uploadFiles}
        />
      ),
      label: "Narrations",
    },
  ];

  return (
    <Loader isLoaded={images !== undefined && narrations !== undefined}>
      <section className={styles["white-section"]}>
        <div className={styles.content}>
          <TabGroup>{items}</TabGroup>
        </div>
      </section>
    </Loader>
  );
};

export default Assets;
