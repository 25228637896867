/**
 * Cookie helpers. To support most browsers, do not exceed 50 cookies per
 * domain and 4093 bytes per domain.
 * Reference: http://browsercookielimits.squawky.net/
 *
 * This file was copied from duolingo-web/src/util/cookie.ts
 */

import Cookies from "js-cookie";
import * as _ from "lodash";

type LocalCookie = "jwt_token";

export const Cookie: Record<string, LocalCookie> = { JWT: "jwt_token" };

/**
 * This value is based on `location.host` instead of on environment (local vs.
 * prod) so that Jenkins CI still works - it can't set cookies on .duolingo.com
 * but otherwise pretends to be prod
 */
const DOMAIN = _.includes(window.location.host, "duolingo.com")
  ? ".duolingo.com"
  : _.includes(window.location.host, "duolingo.cn")
    ? ".duolingo.cn"
    : "localhost";
const EXPIRE_DAYS = 365;

// TODO: Find a way to change 'string' to a stricter type that depends on
// the keys in Cookie
export const getCookie = (name: LocalCookie) => Cookies.get(name);

export const setCookie = (
  name: LocalCookie,
  value: string,
  samesite?: boolean,
) => {
  const cookieSettings: {
    domain: string;
    expires: number;
    SameSite?: string;
  } = { domain: DOMAIN, expires: EXPIRE_DAYS };
  if (samesite) {
    cookieSettings["SameSite"] = "Lax";
  }
  Cookies.set(name, value, cookieSettings);
};

export const removeCookie = (name: LocalCookie) => {
  Cookies.remove(name);
  Cookies.remove(name, { domain: "duolingo.com" });

  Cookies.remove(name, { domain: DOMAIN });
};

export const NO_LANDING_COOKIE = "N/A";
