// See ui_strings/README.md for documentation.

// (The enum is named "R" for "resource", in reference to Android's
// "R.string.string_name" method of localization.)

// Some strings use irregular whitespace, so disable this rule
/* eslint-disable no-irregular-whitespace */
export enum R {
  /** text: "ABC is designed to build fluency so kids can read with speed, accuracy, and expression.<br><br>Activities include reading along with a story narrator, repeating sentences aloud, and identifying sight words." */
  ABC_IS_DESIGNED_TO_BUILD_FLUENCY_SO_KIDS_CAN_READ_WITH_SPEED = 45020,
  /** text: "ABC is simple for kids and doesn’t require a lot of teacher support. Initially, I used it with my kids who were struggling. But once I saw how much they enjoyed it and what it taught, I put everybody on it." */
  ABC_IS_SIMPLE_FOR_KIDS = 45126,
  /** text: "ABOUT" */
  ABOUT = 44118,
  /** text: "After 2 months of Duolingo ABC, kids show improvements in literacy comparable to 2 months of kindergarten!" */
  AFTER_2_MONTHS_OF_DUOLINGO_ABC_KIDS_SHOW_IMPROVEMENTS_IN_LIT = 45025,
  /** text: "Apple App Store Editor’s Choice" */
  APPLE_APP_STORE_EDITORS_CHOICE = 44110,
  /** text: "APPS" */
  APPS = 44120,
  /** text: "A comprehensive phonics curriculum that feels like a game" */
  A_COMPREHENSIVE_PHONICS_CURRICULUM_THAT_FEELS_LIKE_A_GAME = 44085,
  /** text: "Bite-sized chunks" */
  BITESIZED_CHUNKS = 45001,
  /** text: "bring abc to your learners" */
  BRING_ABC_TO_YOUR_LEARNERS = 45029,
  /** text: "Build kids’ skills" */
  BUILD_KIDS_SKILLS = 45007,
  /** text: "Build reading comprehension and word knowledge" */
  BUILD_READING_COMPREHENSION_AND_WORD_KNOWLEDGE = 44097,
  /** text: "(but these were nice, too)" */
  BUT_THESE_WERE_NICE_TOO = 44107,
  /** text: "CAREERS" */
  CAREERS = 44119,
  /** text: "contact us" */
  CONTACT_US = 44113,
  /** text: "CONTACT US" */
  CONTACT_US_CAPITALIZED = 21030,
  /** text: "Designed by education experts to help your child learn to read—100% free!" */
  DESIGNED_BY_EDUCATION_EXPERTS = 44082,
  /** text: "Duolingo ABC was developed by a team of learning scientists, engineers, literacy specialists, illustrators, and parents who believe that kids who read will change the world." */
  DEVELOPED_BY_A_TEAM_OF_LEARNING_SCIENTISTS = 44112,
  /** text: "Differentiated learning" */
  DIFFERENTIATED_LEARNING = 45003,
  /** text: "<span class="subtext"> Download on the </span> App Store" */
  DOWNLOAD_ON_THE_APP_STORE = 44124,
  /** text: "Download the app today and <a class="{{link_class}}" href="{{group_link}}">join our community group</a> to connect and get tips from other teachers already using ABC." */
  DOWNLOAD_THE_APP_TODAY = 45084,
  /** text: "DUOLINGO" */
  DUOLINGO = 44117,
  /** text: "Duolingo ABC’s approach to teaching kids how to read is built on serious science. Our playful lessons feel more like a game than a textbook, and that’s by design: learning to read is easier when you’re having fun." */
  DUOLINGO_ABCS_APPROACH_TO_TEACHING_KIDS_HOW_TO_READ_IS_BUILT = 44996,
  /** text: "effective learning" */
  EFFECTIVE_LEARNING = 44099,
  /** text: "effective screen time" */
  EFFECTIVE_SCREEN_TIME = 45024,
  /** text: "Engage through play" */
  ENGAGE_THROUGH_PLAY = 45005,
  /** text: "Every single student enjoys it. They don’t want to stop. That’s a really amazing thing to watch! I’ve seen 23 classes use it and enjoy it. It’s a really fun way for kids to boost their learning." */
  EVERY_SINGLE_STUDENT_ENJOYS_IT = 45125,
  /** text: "EXPLORE HOW WE TEACH" */
  EXPLORE_HOW_WE_TEACH = 44098,
  /** text: "fluency" */
  FLUENCY = 45019,
  /** text: "For a deeper look at our curriculum, check out our <a class="{{link_class}}" href="{{standards_link}}">Common Core Standards Alignment Guide</a> or our full scope & sequence." */
  FOR_A_DEEPER_LOOK_AT_OUR_CURRICULUM = 45082,
  /** text: "Funny, character-driven stories that help kids experience the joy of reading" */
  FUNNY_CHARACTER_DRIVEN_STORIES_THAT_HELP_KIDS = 44087,
  /** text: "Get help or share feedback with our Teacher Support team!" */
  GET_HELP_OR_SHARE_FEEDBACK_WITH_OUR_TEACHER_SUPPORT_TEAM = 45031,
  /** text: "<span class="subtext"> Get it on </span> Google Play" */
  GET_IT_ON_GOOGLE_PLAY = 44125,
  /** text: "Handwriting" */
  HANDWRITING = 44092,
  /** text: "HELP" */
  HELP = 4574,
  /** text: "helping kids is our biggest reward" */
  HELPING_KIDS_IS_OUR_BIGGEST_REWARD = 44106,
  /** text: "how we teach" */
  HOW_WE_TEACH = 44995,
  /** text: "HOW WE TEACH" */
  HOW_WE_TEACH_CAPITALIZED = 44115,
  /** text: "“I’m amazed how much this app has helped my son with writing, word recognition, spelling, and reading. I have incorporated it into his homeschool curriculum… He is only 4 and is reading and writing so well!”" */
  IM_AMAZED_HOW_MUCH_THIS_APP_HAS_HELPED_MY_SON = 44103,
  /** text: "Independent use" */
  INDEPENDENT_USE = 45009,
  /** text: "Interactive activities that maximize learning." */
  INTERACTIVE_ACTIVITIES_THAT_MAXIMIZE_LEARNING = 45000,
  /** text: "INVESTORS" */
  INVESTORS = 44121,
  /** text: "Kids can be successful with ABC. Other programs can be dry but ABC is character driven, fun, and story-like. I love how comprehension is built into the stories, and how sight words and vocab are interspersed with phonics." */
  KIDS_CAN_BE_SUCCESSFUL_WITH_ABC = 45127,
  /** text: "Kids can play and learn on their own, no wifi required." */
  KIDS_CAN_PLAY_AND_LEARN_ON_THEIR_OWN_NO_WIFI_REQUIRED = 45010,
  /** text: "Kids come to know and love our characters as they follow along story adventures. Our stories highlight humor, social emotional skills, and relatable situations while also checking for comprehension and vocabulary." */
  KIDS_COME_TO_KNOW_AND_LOVE_OUR_CHARACTERS = 45027,
  /** text: "learning to read is hard. we made it fun!" */
  LEARNING_TO_READ_IS_HARD_WE_MADE_IT_FUN = 44081,
  /** text: "learning to read through play" */
  LEARNING_TO_READ_THROUGH_PLAY = 44084,
  /** text: "Learn by doing" */
  LEARN_BY_DOING = 44999,
  /** text: "Learn common tricky words like “one” and “was”" */
  LEARN_COMMON_TRICKY_WORDS_LIKE_ONE_AND_WAS = 44095,
  /** text: "Learn letter sounds and decoding with bite-sized lessons" */
  LEARN_LETTER_SOUNDS_AND_DECODING_WITH_BITESIZED_LESSONS = 44091,
  /** text: "Learn More" */
  LEARN_MORE = 44083,
  /** text: "LEARN MORE ABOUT THE DUOLINGO METHOD" */
  LEARN_MORE_ABOUT_THE_DUOLINGO_METHOD = 45011,
  /** text: "Lessons take 5 minutes or less, keeping kids motivated." */
  LESSONS_TAKE_5_MINUTES_OR_LESS_KEEPING_KIDS_MOTIVATED = 45002,
  /** text: "2020 TIME Best 100 Inventions" */
  N2020_TIME_BEST_100_INVENTIONS = 44109,
  /** text: "2021 Webby Award Winner" */
  N2021_WEBBY_AWARD_WINNER = 44108,
  /** text: "2nd Grade Teacher" */
  N2ND_GRADE_TEACHER = 45130,
  /** text: "“Our 7 year old has struggled to learn to read… but the app is subtly helping him with that without making him feel bad about it which is great! On his first session he didn’t even want to put the app down!”" */
  OUR_7_YEAR_OLD_HAS_STRUGGLED_TO_LEARN_TO_READ = 44105,
  /** text: "our curriculum" */
  OUR_CURRICULUM = 45012,
  /** text: "Our curriculum is based on recommendations by the National Reading Panel" */
  OUR_CURRICULUM_IS_BASED_ON_RECOMMENDATIONS = 44089,
  /** text: "Our gamified approach fosters a love of reading." */
  OUR_GAMIFIED_APPROACH_FOSTERS_A_LOVE_OF_READING = 45006,
  /** text: "Our program is based on the science of reading, which is what research tells us about how children learn to read, and uses delightful content to create a fun, effective learning experience." */
  OUR_PROGRAM_IS_BASED_ON_THE_SCIENCE_OF_READING = 44998,
  /** text: "phonemic awareness" */
  PHONEMIC_AWARENESS = 45085,
  /** text: "Phonics" */
  PHONICS = 44090,
  /** text: "phonics" */
  PHONICS_LOWERCASE = 45014,
  /** text: "Practice writing lowercase and capital letters" */
  PRACTICE_WRITING_LOWERCASE_AND_CAPITAL_LETTERS = 44093,
  /** text: "Pre-K Teacher" */
  PREK_TEACHER = 45129,
  /** text: "PRIVACY" */
  PRIVACY = 44123,
  /** text: "questions?" */
  QUESTIONS = 45030,
  /** text: "Reach out at <a class="{{link_class}}" href="{{mailto_link}}">literacy-feedback@duolingo.com</a> or download our <a class="{{link_class}}" href="{{press_kit_link}}"> Press Kit</a>" */
  REACH_OUT_AT = 45083,
  /** text: "reading comprehension" */
  READING_COMPREHENSION = 45017,
  /** text: "READ THE FULL REPORT" */
  READ_THE_FULL_REPORT = 44101,
  /** text: "Research by Education Development Center shows that kids who used Duolingo ABC for 9 weeks improved their literacy scores by 28%" */
  RESEARCH_BY_EDUCATION_DEVELOPMENT_CENTER_SHOWS = 44100,
  /** text: "RESOURCES" */
  RESOURCES = 44116,
  /** text: "Scaffolded lessons build on what kids know." */
  SCAFFOLDED_LESSONS_BUILD_ON_WHAT_KIDS_KNOW = 45008,
  /** text: "SCOPE & SEQUENCE" */
  SCOPE_AND_SEQUENCE = 45023,
  /** text: "Sight words" */
  SIGHT_WORDS = 44094,
  /** text: "stories kids love" */
  STORIES_KIDS_LOVE = 44086,
  /** text: "stories that teach & delight" */
  STORIES_THAT_TEACH_AND_DELIGHT = 45026,
  /** text: "Tech Lit, Lower Elementary" */
  TECH_LIT_LOWER_ELEMENTARY = 45128,
  /** text: "TERMS" */
  TERMS = 44122,
  /** text: "the science behind abc" */
  THE_SCIENCE_BEHIND_ABC = 44997,
  /** text: "“This app is excellent…the confidence that my daughter has in reading now has increased, it definitely has made her even more interested in learning!”" */
  THIS_APP_IS_EXCELLENT = 44104,
  /** text: "Vocabulary" */
  VOCABULARY = 44096,
  /** text: "vocabulary" */
  VOCABULARY_1 = 45021,
  /** text: "we’re on a mission" */
  WERE_ON_A_MISSION = 44111,
  /** text: "We deepen and expand kids’ vocabulary by scaffolding words that appear in our interactive stories.<br><br>Activities include helping kids identify meaningful prefixes and suffixes like “-ed.”" */
  WE_DEEPEN_AND_EXPAND_KIDS_VOCABULARY = 45022,
  /** text: "We teach comprehension through interactive stories, engaging kids with questions mid-story to encourage active reading.<br><br>Activities guide kids to use comprehension skills like predicting story events and inferring the meaning of new vocabulary." */
  WE_TEACH_COMPREHENSION_THROUGH_INTERACTIVE_STORIES = 45018,
  /** text: "We teach kids to recognize the individual sounds in spoken words.<br><br>Activities include rhyming, identifying specific sounds, and blending those sounds together." */
  WE_TEACH_KIDS_TO_RECOGNIZE_THE_INDIVIDUAL_SOUNDS = 45016,
  /** text: "We teach the five components of literacy instruction that kids need to become successful readers. Our sequenced cumulative learning paths also align to National Reading Panel recommendations and the Common Core Standards for Grades K–2." */
  WE_TEACH_THE_FIVE_COMPONENTS_OF_LITERACY = 45013,
  /** text: "We use explicit, systematic phonics instruction to teach letter sounds, syllable types and other spelling patterns.<br><br>Activities guide kids to start decoding and spelling words as quickly as possible." */
  WE_USE_EXPLICIT_SYSTEMATIC_PHONICS_INSTRUCTION = 45015,
  /** text: "what kids will learn" */
  WHAT_KIDS_WILL_LEARN = 44088,
  /** text: "what parents are saying" */
  WHAT_PARENTS_ARE_SAYING = 44102,
  /** text: "why teachers choose abc" */
  WHY_TEACHERS_CHOOSE_ABC = 45028,
  /** text: "With 9 levels to start from, kids move at their own pace." */
  WITH_9_LEVELS_TO_START_FROM_KIDS_MOVE_AT_THEIR_OWN_PACE = 45004,
}
