import type {
  CourseLevel,
  Lesson,
  LessonPath,
  LessonType,
  Unit,
} from "./commonTypes";

/**
 * Raw lesson data from the lesson path JSON file includes lessons in the order
 * they appear in the course, and each lesson includes information about what
 * level and unit it belongs to. This function loops through those lessons in order
 * and when it encounters a lesson that belongs to a new unit or level, it creates
 * a new unit or level object and adds the previous unit or level to the path.
 */
export const buildLessonPathFromList = (lessons: Lesson[]): LessonPath => {
  const levels: CourseLevel[] = [];
  let currentUnits: Unit[] = [];
  let currentLessons: Lesson[] = [];
  let previousLesson: Lesson;

  lessons.forEach(lesson => {
    // Check to see if we're in a new unit.
    if (lesson.unitID !== previousLesson?.unitID && currentLessons.length > 0) {
      currentUnits.push({
        id: previousLesson.unitID,
        lessons: currentLessons,
        levelID: previousLesson.levelID,
      });
      currentLessons = [];
    }
    // Check to see if the new unit is a part of a new level.
    if (lesson.levelID !== previousLesson?.levelID && currentUnits.length > 0) {
      levels.push({
        id: previousLesson.levelID,
        units: currentUnits,
      });
      currentUnits = [];
    }

    previousLesson = lesson;
    currentLessons.push(lesson);
  });

  // Push the last unit and level.
  if (currentLessons.length > 0) {
    currentUnits.push({
      id: currentLessons[0].unitID,
      lessons: currentLessons,
      levelID: currentLessons[0].levelID,
    });
  }

  if (currentUnits.length > 0) {
    levels.push({
      id: currentUnits[0].levelID,
      units: currentUnits,
    });
  }

  return { levels };
};

export const lessonTypeDisplayString = (lessonType: LessonType) => {
  switch (lessonType) {
    case "affix_cumulative_review":
      return "Affix Cumulative Review";
    case "affix_intro":
      return "Affix Intro";
    case "affix_practice":
      return "Affix Practice";
    case "arrange_story":
      return "Arrange Story";
    case "capital_letter_intro":
      return "Capital Letter Intro";
    case "cumulative_words_practice":
      return "Cumulative Words Practice";
    case "decoding_intro":
      return "Decoding Intro";
    case "letter":
      return "Letter";
    case "letter_team":
      return "Letter Team";
    case "name_intro":
      return "Name Intro";
    case "name_reconstruction":
      return "Name Reconstruction";
    case "name_review":
      return "Name Review";
    case "originals_story":
      return "Originals Story";
    case "pattern_intro":
      return "Pattern Intro";
    case "repeat_story":
      return "Repeat Story";
    case "review":
      return "Review";
    case "rhyming_intro":
      return "Rhyming Intro";
    case "shapes_1":
      return "Shapes 1";
    case "shapes_2":
      return "Shapes 2";
    case "sight_word_intro":
      return "Sight Word Intro";
    case "story_intro":
      return "Story Intro";
    case "story_review":
      return "Story Review";
    case "tracing_cumulative_review":
      return "Tracing Cumulative Review";
    case "tracing_intro":
      return "Tracing Intro";
    case "words_practice":
      return "Words Practice";
    default:
      return lessonType;
  }
};
