import cn from "classnames";

import { R } from "strings/definitions";
import { _i } from "util/i18n";

import styles from "./FooterLinks.module.scss";

const FooterLinks = ({ className }: { className?: string }) => (
  <nav className={cn(styles.wrap, className)}>
    <a className={styles.link} href="https://duolingo.com">
      {_i(R.DUOLINGO)}
    </a>
    <a className={styles.link} href="https://duolingo.com/info">
      {_i(R.ABOUT)}
    </a>
    <a className={styles.link} href="https://careers.duolingo.com">
      {_i(R.CAREERS)}
    </a>
    <a className={styles.link} href="https://duolingo.com/mobile">
      {_i(R.APPS)}
    </a>
    <a className={styles.link} href="https://investors.duolingo.com">
      {_i(R.INVESTORS)}
    </a>
    <a className={styles.link} href="https://duolingo.com/help">
      {_i(R.HELP)}
    </a>
    <a className={styles.link} href="https://duolingo.com/terms">
      {_i(R.TERMS)}
    </a>
    <a className={styles.link} href="https://duolingo.com/abc-privacy">
      {_i(R.PRIVACY)}
    </a>
  </nav>
);

export default FooterLinks;
