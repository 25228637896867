import React, { createContext, useEffect, useState } from "react";

import { getUser } from "api";
import type { User } from "util/commonTypes";
import { getLoggedInUserId } from "util/user";

type UserState = User | null | undefined;

export const UserContext = createContext<UserState>(undefined);

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<UserState>(undefined);
  useEffect(() => {
    const userId = getLoggedInUserId();
    if (userId === undefined) {
      setUser(null);
      return;
    }

    const fetchAndSetUser = async () => {
      setUser(await getUser(userId));
    };
    fetchAndSetUser();
  }, [setUser]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default UserProvider;
