import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import Loader from "components/common/Loader";
import { UserContext } from "contexts/User";
import imageFofoComputing from "images/fofo-working.gif";
import { UserAccessLevel } from "util/commonTypes";
import { LiteracyPath } from "util/constants";
import { getAccessLevel } from "util/user";

import styles from "./Admin.module.scss";

const Admin = () => {
  const user = useContext(UserContext);
  const location = useLocation();

  return (
    <Loader isLoaded={user !== undefined}>
      {!user || getAccessLevel(user) < UserAccessLevel.ADMIN ? (
        <Navigate replace={true} state={{ from: location }} to="/" />
      ) : (
        <div>
          {location.pathname === LiteracyPath.ADMIN ? (
            <section className={styles["white-section"]}>
              <div className={styles.content}>
                <div className={styles.text}>
                  <h1 className={styles.title}>Admin Tools</h1>
                  <p className={styles.subtitle}>
                    Hello wonderful coworker! Select a tool from the navigation
                    bar to get started. In the meantime, enjoy FoFo hard at
                    work.
                  </p>
                </div>
                <img className={styles.image} src={imageFofoComputing} />
              </div>
            </section>
          ) : (
            <Outlet />
          )}
        </div>
      )}
    </Loader>
  );
};

export default Admin;
