import axios from "axios";

import type { Language, Lesson, LessonPath, User } from "util/commonTypes";
import { Cookie, getCookie } from "util/cookies";
import { buildLessonPathFromList } from "util/lessons";

const literacyApi = axios.create({
  baseURL: "https://literacy-api.duolingo.com",
  headers: {
    Authorization: `Bearer ${getCookie(Cookie.JWT)}`,
  },
  withCredentials: true,
});

const literacyCDN = axios.create({
  baseURL: "https://lit-lessons-cdn.duolingo.com",
});

// If we ever get a 401 response from the API, redirect back home.
literacyApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      window.location.href = "/";
    }
  },
);

export const getUser = async (userId: number): Promise<User> =>
  (await literacyApi.get(`/1/users/${userId}`)).data.profile;

export const getAllImages = async (): Promise<string[]> =>
  (await literacyApi.get("/2/course-assets/images")).data;

export const uploadImages = async (data: FormData): Promise<string[]> =>
  (await literacyApi.post("/2/course-assets/images", data)).data;

export const getAllNarrations = async (language: Language): Promise<string[]> =>
  (await literacyApi.get(`/2/course-assets/narrations/${language}`)).data;

export const uploadNarrations = async (
  data: FormData,
  language: Language,
): Promise<string[]> =>
  (await literacyApi.post(`/2/course-assets/narrations/${language}`, data))
    .data;

export const getLessonPath = async (
  language: Language,
): Promise<LessonPath> => {
  const lessons: Lesson[] = (
    await literacyCDN.get(`/course-data/${language}/lesson_path_data.json`)
  ).data.lessons;
  return buildLessonPathFromList(lessons);
};
