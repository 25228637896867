import React, { useEffect } from "react";
import { LoadingDots } from "web-ui";

interface Props {
  load?: () => void;
  isLoaded: boolean;
  children?: React.ReactNode;
}

const Loader = ({ load, isLoaded, children }: Props) => {
  useEffect(() => {
    load?.();
  }, [load]);

  return (
    <>
      {isLoaded ? (
        <div>{children}</div>
      ) : (
        <div className="loader">
          <LoadingDots type="screen-white" />
        </div>
      )}
    </>
  );
};

export default Loader;
