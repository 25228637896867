export type Language = "en" | "es";

export type UserRole = "admin";

export interface User {
  id: number;
  email?: string;
  roles: UserRole[];
}

export const enum UserAccessLevel {
  ADMIN = 10,
  LIMITED = 1,
}

export type LessonType =
  | "affix_cumulative_review"
  | "affix_intro"
  | "affix_practice"
  | "arrange_story"
  | "capital_letter_intro"
  | "cumulative_words_practice"
  | "decoding_intro"
  | "letter"
  | "letter_team"
  | "name_intro"
  | "name_reconstruction"
  | "name_review"
  | "originals_story"
  | "pattern_intro"
  | "repeat_story"
  | "review"
  | "rhyming_intro"
  | "shapes_1"
  | "shapes_2"
  | "sight_word_intro"
  | "story_intro"
  | "story_review"
  | "tracing_cumulative_review"
  | "tracing_intro"
  | "words_practice";

export interface Lesson {
  id: string;
  type: LessonType;
  unitID: string;
  levelID: string;
}

export interface Unit {
  id: string;
  lessons: Lesson[];
  levelID: string;
}

export interface CourseLevel {
  id: string;
  units: Unit[];
}

export interface LessonPath {
  levels: CourseLevel[];
}
