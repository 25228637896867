import { useEffect, useState } from "react";

import { getLessonPath } from "api";
import Accordion from "components/common/Accordion";
import Loader from "components/common/Loader";
import imageStoryNode from "images/path-book.svg";
import imageLessonNode from "images/path-lesson.svg";
import type {
  CourseLevel,
  Language,
  Lesson,
  LessonPath,
} from "util/commonTypes";
import { AllCourses } from "util/course";
import { lessonTypeDisplayString } from "util/lessons";

import styles from "./CourseData.module.scss";

const LessonView = ({ lesson }: { lesson: Lesson }) => (
  <div className={styles["lesson"]} title={JSON.stringify(lesson, null, 2)}>
    <img
      className={styles["lesson-image"]}
      src={lesson.type.includes("story") ? imageStoryNode : imageLessonNode}
    />
    <h3>{lessonTypeDisplayString(lesson.type)}</h3>
  </div>
);

const UnitView = ({ lessons }: { lessons: Lesson[] }) => (
  <div className={styles["unit-container"]}>
    <div>
      {lessons.map((lesson, index) => (
        <LessonView key={index} lesson={lesson} />
      ))}
    </div>
  </div>
);

const LessonPathView = ({ levels }: { levels: CourseLevel[] }) => {
  const [currentLevel, setCurrentLevel] = useState<CourseLevel>();

  return (
    <div className={styles["lesson-path"]}>
      <div className={styles["level-select"]}>
        <h2 className={styles["level-select-text"]}>Level:</h2>
        <select
          className={styles["level-select-dropdown"]}
          defaultValue=""
          onChange={e =>
            setCurrentLevel(levels.find(level => level.id === e.target.value))
          }
          value={currentLevel?.id}
        >
          <option disabled={true} value="">
            Select a level
          </option>
          {levels.map((level, index) => (
            <option key={index} value={level.id}>
              {level.id}
            </option>
          ))}
        </select>
      </div>
      <p className={styles["level-description"]}>
        Hover over a lesson to see details.
      </p>
      {levels.map((level, levelIndex) => (
        <div
          key={levelIndex}
          style={{ display: currentLevel?.id === level.id ? "block" : "none" }}
        >
          <Accordion
            accordionData={level.units.map((unit, unitIndex) => ({
              content: <UnitView key={unitIndex} lessons={unit.lessons} />,
              title: unit.id,
            }))}
          />
        </div>
      ))}
    </div>
  );
};

const CourseData = () => {
  const [language, setLanguage] = useState<Language>("en");
  const [lessonPath, setLessonPath] = useState<LessonPath>();

  useEffect(() => {
    let ignore = false;

    const fetchAndSetData = async () => {
      const res = await getLessonPath(language);
      if (!ignore) {
        setLessonPath(res);
      }
    };

    fetchAndSetData();
    return () => {
      ignore = true;
    };
  }, [language, setLessonPath]);

  return (
    <div>
      <select
        onChange={e =>
          setLanguage(AllCourses.find(l => l === e.target.value) ?? "en")
        }
      >
        {AllCourses.map((course, index) => (
          <option key={index} value={index}>
            {course}
          </option>
        ))}
      </select>
      <Loader isLoaded={lessonPath !== undefined}>
        <section className={styles["white-section"]}>
          <div className={styles.content}>
            <LessonPathView levels={lessonPath?.levels ?? []} />
          </div>
        </section>
      </Loader>
    </div>
  );
};

export default CourseData;
