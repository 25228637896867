import InlineSVG from "react-inlinesvg";

import imageAwardLeft from "images/award-left.svg";
import imageAwardRight from "images/award-right.svg";

import styles from "./Award.module.scss";

interface Item {
  color: string;
  text: string;
}

const Award = ({ item: { color, text } }: { item: Item }) => (
  <div className={styles.award}>
    <InlineSVG
      className={styles["award-image"]}
      src={imageAwardLeft}
      style={{ fill: color }}
    />
    <div className={styles["award-text"]}>{text}</div>
    <InlineSVG
      className={styles["award-image"]}
      src={imageAwardRight}
      style={{ fill: color }}
    />
  </div>
);

export default Award;
