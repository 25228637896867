export enum LiteracyPath {
  HOME = "/",
  HOW_WE_TEACH = "/how-we-teach",
  RESOURCES = "/resources",
  ADMIN = "/admin",
  ASSETS = "/admin/assets",
  COURSE_DATA = "/admin/course-data",
}

export const DesktopMinWidth = 700;

export const enum Links {
  IOS = "https://apps.apple.com/app/apple-store/id1440502568?pt=1374442&ct=Web%20splash&mt=8",
  Android = "https://play.google.com/store/apps/details?id=com.duolingo.literacy",
  Email = "literacy-feedback@duolingo.com",
  PressKit = "https://drive.google.com/drive/folders/1Q1tchUIctY0IVJvlbKmAq3ZFmngtwbTt?usp=sharing",
  TeacherFacebook = "https://www.facebook.com/groups/328904512695428",
}

export const BaseS3URL =
  "https://s3.amazonaws.com/lit-lessons-cdn.duolingo.com";
export const BaseCdnURL = "https://lit-lessons-cdn.duolingo.com";
export const BasePrintableURL = `${BaseCdnURL}/resources/printables`;
export const ScopeDocument = `${BaseCdnURL}/resources/duolingo_abc_scope_and_sequence_english.pdf`;

export const Letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "y",
  "z",
];
