import * as excess from "web-excess";

excess.init();
excess.setDebug(process.env.NODE_ENV === "development");
excess.register({ Client: "web" });

export type Page = "home" | "how_we_teach" | "resources";
export type ClickTarget =
  | "ios_download"
  | "android_download"
  | "efficacy_page"
  | "efficacy_report"
  | "feedback_email"
  | "press_kit"
  | "printable_activity"
  | "scope_and_sequence_doc"
  | "standards_alignment_guide"
  | "teacher_facebook_group";

interface TrackingEvents {
  lit_web_page_load: {
    page: Page;
  };
  lit_web_click: {
    page: Page;
    target: ClickTarget;
    description?: string;
  };
  cookie_consent_updated: {
    cookie_consent_groups: string;
  };
}

const track = <T extends keyof TrackingEvents>(
  event: T,
  props?: TrackingEvents[T],
) =>
  new Promise<void>(resolve => {
    excess.track(event, props, () => resolve);
  });

export default track;
