import cn from "classnames";
import React, { useState } from "react";

import styles from "./TabGroup.module.scss";

interface TabProps {
  active: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const Tab = ({ active, onClick, children }: TabProps) => (
  <div
    className={cn(styles.tab, {
      [styles["tab-active"]]: active,
    })}
    onClick={onClick}
  >
    {children}
  </div>
);

interface TabItem {
  label: string;
  content: React.ReactNode;
}

interface Props {
  children: TabItem[];
}

const TabGroup = ({ children }: Props) => {
  const [currentIndex, setIndex] = useState(0);

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {children.map((child, index) => (
          <Tab
            active={index === currentIndex}
            key={index}
            onClick={() => setIndex(index)}
          >
            {child.label}
          </Tab>
        ))}
      </div>
      <div>{children[currentIndex].content}</div>
    </div>
  );
};

export default TabGroup;
