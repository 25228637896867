import { Base64 } from "js-base64";
import * as _ from "lodash";

import { Cookie, getCookie, removeCookie } from "util/cookies";

import { UserAccessLevel } from "./commonTypes";
import type { User } from "./commonTypes";

export const getAccessLevel = (user?: User): UserAccessLevel => {
  const roles = user?.roles ?? [];

  if (roles.includes("admin")) {
    return UserAccessLevel.ADMIN;
  }

  return UserAccessLevel.LIMITED;
};

/**
 * Gets the ID of the user whom the browser's cookies claim to be authenticated.
 * (Since we don't have any key secrets, we can't know for sure whether the
 * cookies are actually valid.) This value is analogous to Pyramid's
 * `unauthenticated_userid`.
 */
export const getLoggedInUserId = () => {
  let jwtUserId;

  // Determine user ID according to JWT cookie
  const jwt = getCookie(Cookie.JWT);
  if (jwt) {
    try {
      jwtUserId = parseInt(
        JSON.parse(Base64.decode(jwt.split(".")[1])).sub,
        10,
      );
    } catch {
      /* eslint-disable-next-line no-console */
      console.error("Failed to parse saved JWT", { data: { jwt }, once: true });
    }
  }

  return jwtUserId;
};

export const logout = () => {
  _.values(Cookie).forEach(cookie => removeCookie(cookie));
};
