import cn from "classnames";
import * as React from "react";
import { setIn } from "seamless-immutable";

import imageCaretDown from "images/caret-down.svg";
import imageCaretUp from "images/caret-up.svg";

import styles from "./Accordion.module.scss";

interface AccordionData {
  title: string;
  content: React.ReactNode;
}

interface AccordionProps {
  accordionData: AccordionData[];
  className?: string;
  startingOpenFoldIndices?: number[];
}

const getCaretIcon = (showDescription: boolean) => {
  const image = showDescription ? imageCaretUp : imageCaretDown;
  return <img alt="caret" className={styles.caret} src={image} />;
};

const Accordion = ({
  accordionData,
  className,
  startingOpenFoldIndices,
}: AccordionProps) => {
  let startingOpenFolds = new Array(accordionData.length).fill(false);
  if (startingOpenFoldIndices !== undefined) {
    startingOpenFoldIndices.forEach(i => {
      if (i >= accordionData.length) {
        throw new Error(
          `startingOpenFoldIndices must be less than the number of accordion items. Got ${i} but there are ${accordionData.length} accordion items.`,
        );
      }
      startingOpenFolds = setIn(startingOpenFolds, [i], true);
    });
  }

  const [expandedFolds, setExpandedFolds] =
    React.useState<boolean[]>(startingOpenFolds);
  return (
    <div className={cn(styles.container, className)}>
      {accordionData.map(({ content, title }: AccordionData, i) => (
        <>
          <div
            className={styles.item}
            key={i}
            onClick={() => {
              setExpandedFolds(value => setIn(value, [i], !value[i]));
            }}
          >
            <div className={styles.content}>
              <div className={styles["item-top"]}>
                <p className={styles.title}>{title}</p>
                {getCaretIcon(expandedFolds[i])}
              </div>
              {expandedFolds[i] ? (
                <div
                  className={styles["expanded-content"]}
                  onClick={e => e.stopPropagation()}
                >
                  {content}
                </div>
              ) : null}
            </div>
          </div>
          {i < accordionData.length - 1 ? <hr className={styles.hr} /> : null}
        </>
      ))}
    </div>
  );
};

export default Accordion;
