import * as _ from "underscore";

import { getQueryParams } from "util/http";

/** Checks if user is on staging server */
export const isStagingServer = _.once(() =>
  window.location.host.includes("stage"),
);

type DebugFlag = "forceUiLanguage";

export const getDebugFlag = (flag: DebugFlag) =>
  getQueryParams(window.location.search)[flag];
