/* eslint-disable */

// Don't edit this file directly! Since it was generated by a script, any
// changes made here will be lost. See ui_strings/README.md for full documentation.

// The "undeclared" key is sourced from all Incubator string resources tagged
// with either "web" or "data" that aren't already declared in the enum. Most of
// those resources are either obsolete or the English names of languages. We
// don't need the obsolete strings, but we do need the language names because
// they're referenced inside strings' {{_ivar}} and {{_iv}} blocks.
// Since those two Mustache tags are *supposed* to wrap only variables that
// resolve to English names of languages (e.g. French -> francés in Spanish UI),
// only "web"/"data" strings associated with the English name of a language are
// included in the "undeclared" key.
// Occasionally, you might come across {{_ivar}}/{{_iv}} used with a variable
// that doesn't resolve to the English name of a language, e.g. "basics_title".
// That's a mistake on the resource submitter's part; they should've just used
// {{basics_title}} instead. Since Incubator doesn't have translations for
// variables that don't end up being an English language name post-resolution,
// the attempt to translate "Basics 1" will silently fail and result in simple
// insertion as-is anyway. (Karin confirms that this behavior is according to
// spec.)

import type { LocalizationData } from "localization-lib";

import data from "strings/es.duostrings";
import fallback_data from "strings/en.duostrings";
export default {
  getFallback: () => fallback_data.sources,
  getStrings: () => data.sources,
  getUndeclared: () => {},
  uiLanguage: "es",
} as LocalizationData;
