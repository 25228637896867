import cn from "classnames";
import * as React from "react";
import * as _ from "underscore";

import styles from "./Carousel.module.scss";

/**
 * The distance the user needs to swipe before the carousel triggers the
 * next/previous item.
 */
const SWIPE_DISTANCE = 50; // px;

interface CarouselState {
  currentIndex: number;
  currentSwipe?: {
    currentX: number;
    startX: number;
  };
  nextIndex?: number;
  transitionDirection: "left" | "right";
  transitionStatus: "in" | "out" | "initial";
}

type SwipeAction =
  | {
      type: "swipeStart" | "swipeMove";
      xPosition: number;
    }
  | { type: "swipeEnd" };

type CarouselAction =
  | { type: "advance"; offset?: number }
  | { type: "advanceTo"; targetIndex: number }
  | { type: "finishSlideOut" }
  | SwipeAction;

/**
 * Carousel logic that can be reused for different carousel types.
 */
export const useCarousel = (items: number, autoAdvanceDelay: number) => {
  /** Ensure an index is an index into `items`, wrapping under- and over-flows. */
  const wrapIndex = (index: number) => ((index % items) + items) % items;

  const carouselReducer = (
    state: CarouselState,
    action: CarouselAction,
  ): CarouselState => {
    switch (action.type) {
      case "advance": {
        const offset = action.offset ?? 1;
        return {
          ...state,
          nextIndex: wrapIndex(state.currentIndex + offset),
          transitionDirection: offset > 0 ? "left" : "right",
          transitionStatus: "out",
        };
      }
      case "advanceTo": {
        return {
          ...state,
          nextIndex: action.targetIndex,
          transitionDirection:
            action.targetIndex > state.currentIndex ? "left" : "right",
          transitionStatus: "out",
        };
      }
      case "finishSlideOut": {
        return {
          currentIndex: state.nextIndex ?? state.currentIndex,
          transitionDirection: state.transitionDirection,
          transitionStatus: "in",
        };
      }
      case "swipeStart": {
        return state.nextIndex === undefined && state.currentSwipe === undefined
          ? {
              ...state,
              currentSwipe: {
                currentX: action.xPosition,
                startX: action.xPosition,
              },
            }
          : state;
      }
      case "swipeMove": {
        if (state.nextIndex !== undefined || state.currentSwipe === undefined) {
          return state;
        }

        const swipeOffset = action.xPosition - state.currentSwipe.startX;
        if (Math.abs(swipeOffset) > SWIPE_DISTANCE) {
          return carouselReducer(state, {
            offset: swipeOffset < 0 ? 1 : -1,
            type: "advance",
          });
        }

        return {
          ...state,
          currentSwipe: {
            currentX: action.xPosition,
            startX: state.currentSwipe.startX,
          },
        };
      }
      case "swipeEnd": {
        return state.nextIndex === undefined && state.currentSwipe !== undefined
          ? {
              ...state,
              currentSwipe: undefined,
            }
          : state;
      }
      default: {
        throw Error("Unknown carousel action");
      }
    }
  };

  const [carouselState, dispatch] = React.useReducer(carouselReducer, {
    currentIndex: 0,
    transitionDirection: "left",
    transitionStatus: "initial",
  });

  // Auto-advance
  const isSwiping = !!carouselState.currentSwipe;
  React.useEffect(() => {
    // Only auto-advance when not swiping
    if (autoAdvanceDelay > 0 && !isSwiping) {
      const id = setTimeout(
        () => dispatch({ type: "advance" }),
        autoAdvanceDelay,
      );
      return () => clearTimeout(id);
    }

    return undefined;
  }, [autoAdvanceDelay, carouselState.currentIndex, isSwiping]);

  return {
    ...carouselState,
    dispatchToCarousel: dispatch,
  };
};

export const DotsNav = ({
  className,
  currentIndex,
  items,
  onChange,
  transitionStatus,
}: {
  className?: string;
  currentIndex: number;
  items: number;
  onChange: (index: number) => void;
  transitionStatus: CarouselState["transitionStatus"];
}) => (
  <nav className={cn(styles.dots, className)}>
    {_.times(items, index => (
      <div
        className={
          styles[
            `dot${
              index === currentIndex && transitionStatus !== "out"
                ? "-selected"
                : ""
            }`
          ]
        }
        key={index}
        onClick={() => onChange(index)}
      />
    ))}
  </nav>
);

export const SwipeableCarouselItem = ({
  className,
  children,
  currentSwipe,
  dispatchToCarousel,
}: {
  className?: string;
  children: React.ReactNode;
  currentSwipe: CarouselState["currentSwipe"];
  dispatchToCarousel: React.Dispatch<CarouselAction>;
}) => {
  const handleMouseInput =
    (type: SwipeAction["type"]) => (e: React.MouseEvent) => {
      dispatchToCarousel(
        type === "swipeEnd" ? { type } : { type, xPosition: e.clientX },
      );
    };

  const handleTouchInput =
    (type: SwipeAction["type"]) => (e: React.TouchEvent) => {
      dispatchToCarousel(
        type === "swipeEnd"
          ? { type }
          : {
              type,
              xPosition: e.changedTouches[0].clientX,
            },
      );
    };

  const currentSwipeOffset = currentSwipe
    ? currentSwipe.currentX - currentSwipe.startX
    : 0;

  // End an in-progress swipe when component is unmounted
  React.useEffect(
    () => () => dispatchToCarousel({ type: "swipeEnd" }),
    [dispatchToCarousel],
  );

  return (
    <div
      className={cn(className, {
        [styles["swipe-reset"]]: !currentSwipe,
      })}
      onMouseDown={handleMouseInput("swipeStart")}
      onMouseLeave={handleMouseInput("swipeEnd")}
      onMouseMove={handleMouseInput("swipeMove")}
      onMouseUp={handleMouseInput("swipeEnd")}
      onTouchEnd={handleTouchInput("swipeEnd")}
      onTouchMove={handleTouchInput("swipeMove")}
      onTouchStart={handleTouchInput("swipeStart")}
      style={{
        // Fade out from 100% to 50% during swipe
        opacity: 1 - (Math.abs(currentSwipeOffset) * 0.5) / SWIPE_DISTANCE,
        transform: `translateX(${currentSwipeOffset}px)`,
      }}
    >
      {children}
    </div>
  );
};

interface Props {
  autoAdvanceSpeed?: number; // ms
  children: (index: number) => React.ReactNode;
  className?: string;
  dotsClassName?: string;
  items: number;
}

const Carousel = ({
  autoAdvanceSpeed = 8000,
  children,
  className,
  dotsClassName,
  items,
}: Props) => {
  const {
    currentIndex,
    currentSwipe,
    dispatchToCarousel,
    transitionDirection,
    transitionStatus,
  } = useCarousel(items, autoAdvanceSpeed);

  const handleNavClick = (index: number) =>
    index !== currentIndex &&
    dispatchToCarousel({
      targetIndex: index,
      type: "advanceTo",
    });

  return (
    <div className={cn(styles.container, className)}>
      <SwipeableCarouselItem
        currentSwipe={currentSwipe}
        dispatchToCarousel={dispatchToCarousel}
      >
        <div
          className={cn({
            [styles[`item-leave-${transitionDirection}-small`]]:
              transitionStatus === "out",
            [styles[`item-spring-${transitionDirection}-small`]]:
              transitionStatus === "in",
          })}
          onAnimationEnd={() => dispatchToCarousel({ type: "finishSlideOut" })}
        >
          {children(currentIndex)}
        </div>
      </SwipeableCarouselItem>
      <DotsNav
        className={cn(styles["dots-below"], dotsClassName)}
        currentIndex={currentIndex}
        items={items}
        onChange={handleNavClick}
        transitionStatus={transitionStatus}
      />
    </div>
  );
};

export default Carousel;
