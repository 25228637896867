import "util/onetrust";

import * as ReactDOM from "react-dom/client";

import App from "components/App";

const container = document.getElementById("root");
if (!container) {
  throw new Error("Root element not found");
}

ReactDOM.createRoot(container).render(<App />);
