import cn from "classnames";
import { getButtonClassName } from "web-ui";

import styles from "./FileSelector.module.scss";

const FileSelector = ({
  className,
  multiple,
  fileTypes,
  selectFiles,
}: {
  className?: string;
  multiple: boolean;
  fileTypes?: string[];
  selectFiles: (files: FileList | null) => void;
}) => (
  <div className={cn(className)}>
    <label
      className={getButtonClassName({ color: "owl", variant: "solid" })}
      htmlFor="file-input"
    >
      Select Files
    </label>
    <input
      accept={fileTypes && fileTypes.length > 0 ? fileTypes.join(",") : "*"}
      className={styles["file-input"]}
      id="file-input"
      multiple={multiple}
      onChange={e => selectFiles(e.target.files)}
      type="file"
    />
  </div>
);

export default FileSelector;
