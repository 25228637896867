import track from "util/track";

window.OptanonWrapper = () => {
  // Track updates to the cookie consent
  if (window.OnetrustActiveGroups) {
    track("cookie_consent_updated", {
      cookie_consent_groups: window.OnetrustActiveGroups,
    });
  }
};
