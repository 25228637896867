import { translate } from "localization-lib";
import React from "react";
import * as ReactDOMServer from "react-dom/server";

import enStringData from "strings/en";
import esStringData from "strings/es";
import { getDebugFlag } from "util/debug";

const LANGUAGES: UiLanguage[] = ["en", "es"];

const localizationData = {
  en: enStringData,
  es: esStringData,
};

const getUiLanguage = (): UiLanguage => {
  /*
   * TODO: Once Spanish localization is complete and ready, uncomment
   * the following code and use it to determine the UI language. Until
   * then continue to use English.
   *
   * const browserLanguage = navigator.language;
   * const trimmedLanguage = browserLanguage.split("-")[0];
   * if (LANGUAGES.includes(trimmedLanguage as UiLanguage)) {
   * return trimmedLanguage;
   * }
   * return "en";
   */
  const forcedUiLanguage = getDebugFlag("forceUiLanguage");
  if (
    forcedUiLanguage !== undefined &&
    LANGUAGES.includes(forcedUiLanguage as UiLanguage)
  ) {
    return forcedUiLanguage as UiLanguage;
  }

  return "en";
};

export const uiLanguage = getUiLanguage();

/**
 * Translate the given string resource into the current UI language. This
 * function's return value may be either a string or a React element.
 * If you require a string (e.g. for placeholder or title attributes),
 * use `_iText` instead.
 */
export const _i = (
  stringId: number,
  variables?: Record<string, number | string>,
  options?: Record<string, unknown>,
): React.ReactElement | string =>
  translate(
    localizationData[uiLanguage],
    console.log,
    stringId,
    variables,
    options,
  );

/**
 * Same as `_i` but always returns a string. Since rendering a ReactElement to
 * a string is expensive, this function should be used only when a string
 * (instead of a ReactElement) is absolutely necessary, e.g. inside placeholder
 * and title attributes.
 */
export const _iText = (
  stringId: number,
  variables?: Record<string, number | string>,
  options?: Record<string, unknown>,
): string => {
  const reactChild = _i(stringId, variables, options);
  if (
    typeof reactChild === "number" ||
    typeof reactChild === "string" ||
    typeof reactChild === "boolean"
  ) {
    return reactChild.toString();
  }
  // to handle the case where _i returns an non-react object
  // possible since translate calls JSON.parse, which may return
  // an arbitrary object
  if (!React.isValidElement(reactChild)) {
    return JSON.stringify(reactChild);
  }
  try {
    const div = document.createElement("div");
    div.innerHTML = ReactDOMServer.renderToStaticMarkup(reactChild);
    return div.innerText;
  } catch {
    console.log("Failed to convert ReactElement to string", {
      data: { stringId },
    });
    return "";
  }
};
