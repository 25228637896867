import type { Location } from "@remix-run/router";
import cn from "classnames";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { List } from "web-ui";
import type { ListChangeEvent } from "web-ui/dist/types/components/List";

import imageLogo from "images/abc-logo.svg";
import { R } from "strings/definitions";
import { LiteracyPath } from "util/constants";
import { _iText, uiLanguage } from "util/i18n";

import styles from "./Topbar.module.scss";

const HOW_WE_TEACH_TITLE = _iText(R.HOW_WE_TEACH_CAPITALIZED);
const RESOURCES_TITLE = _iText(R.RESOURCES);
const ASSETS_TITLE = "ASSETS";
const COURSE_DATA_TITLE = "COURSE DATA";

interface Item {
  path: LiteracyPath;
  supportedLocales?: UiLanguage[];
  text: string;
}

const filterItemsByLocale = (items: Item[]) =>
  items.filter(item =>
    item.supportedLocales ? item.supportedLocales.includes(uiLanguage) : true,
  );

const PUBLIC_ITEMS: Item[] = [
  {
    path: LiteracyPath.HOW_WE_TEACH,
    supportedLocales: ["en", "es"],
    text: HOW_WE_TEACH_TITLE,
  },
  {
    path: LiteracyPath.RESOURCES,
    supportedLocales: ["en"],
    text: RESOURCES_TITLE,
  },
];

const ADMIN_ITEMS: Item[] = [
  {
    path: LiteracyPath.ASSETS,
    text: ASSETS_TITLE,
  },
  {
    path: LiteracyPath.COURSE_DATA,
    text: COURSE_DATA_TITLE,
  },
];

interface NavItemsProps {
  location: Location;
  items: Item[];
}

const NavItems = ({ location, items }: NavItemsProps) => (
  <>
    {items.map(item => (
      <Link
        className={styles["nav-item"]}
        key={item.path}
        onClick={() => window.scrollTo({ top: 0 })}
        to={`${item.path}${location.search}`}
      >
        <div className={styles.item}>
          <div
            className={
              location.pathname === item.path
                ? styles["nav-item-text-selected"]
                : styles["nav-item-text"]
            }
          >
            {item.text}
          </div>
        </div>
      </Link>
    ))}
  </>
);

interface MobileNavProps {
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  items: Item[];
  location: Location;
}

const MobileNavItems = ({
  setShowMobileMenu,
  items,
  location,
}: MobileNavProps) => {
  const navigate = useNavigate();

  const onChange = (e: ListChangeEvent) => {
    if (e.selectedIndices.length > 0) {
      const item = items[e.selectedIndices[0]];
      navigate(`${item.path}${location.search}`);
      window.scrollTo({ top: 0 });
      setShowMobileMenu(false);
    }
  };

  return (
    <List
      className={styles["mobile-menu-nav-items"]}
      inPopover={true}
      items={items}
      onChange={onChange}
      selectedIndices={[
        items.findIndex(item => item.path === location.pathname),
      ]}
    />
  );
};

interface TopbarProps {
  inputRef: React.RefObject<HTMLDivElement>;
}

const Topbar = ({ inputRef }: TopbarProps) => {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const location = useLocation();
  const currentPage = location.pathname;
  const onAdminRoute = currentPage.startsWith(LiteracyPath.ADMIN);
  const items = onAdminRoute ? ADMIN_ITEMS : filterItemsByLocale(PUBLIC_ITEMS);

  return (
    <>
      <header className={styles.wrap} ref={inputRef}>
        <div className={styles.container}>
          <Link
            onClick={() => window.scrollTo({ top: 0 })}
            to={`${LiteracyPath.HOME}${location.search}`}
          >
            <img alt="Duolingo ABC" className={styles.logo} src={imageLogo} />
          </Link>
          <div className={styles["right-pinned-buttons"]}>
            <div className={styles["desktop-nav"]}>
              <NavItems items={items} location={location} />
            </div>
            <button
              className={
                showMobileMenu ? styles["hamburger-open"] : styles.hamburger
              }
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <span className={styles.bar} />
              <span className={styles.bar} />
              <span className={styles.bar} />
            </button>
          </div>
        </div>
      </header>
      <div className={showMobileMenu ? "" : styles["mobile-menu-hidden"]}>
        <div
          className={cn(styles.overlay, {
            [styles["overlay-open"]]: showMobileMenu,
          })}
          onClick={() => setShowMobileMenu(false)}
        />
        <div
          className={
            showMobileMenu ? styles["mobile-menu-open"] : styles["mobile-menu"]
          }
        >
          <MobileNavItems
            items={items}
            location={location}
            setShowMobileMenu={setShowMobileMenu}
          />
        </div>
      </div>
    </>
  );
};

export default Topbar;
