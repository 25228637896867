import cn from "classnames";
import { useEffect } from "react";
import { getButtonClassName } from "web-ui";

import FooterLinks from "components/common/FooterLinks";
import imageHillTop from "images/hill-top-purple.svg";
import imageSectionBottom from "images/inverse-section-bottom-purple.svg";
import imageHero from "images/resources-hero.svg";
import { BasePrintableURL, Letters, Links } from "util/constants";
import track from "util/track";

import styles from "./Resources.module.scss";

const TRACKING_PAGE = "resources";

const PrintableCard = ({ letter }: { letter: string }) => (
  <a
    className={styles.card}
    href={`${BasePrintableURL}/${letter}/duolingo_abc_${letter}_printable.pdf`}
    onClick={() =>
      track("lit_web_click", {
        description: `letter_${letter}`,
        page: TRACKING_PAGE,
        target: "printable_activity",
      })
    }
    rel="noreferrer"
    target="_blank"
  >
    <div className={styles["card-top"]}>
      <img
        className={styles["card-letters"]}
        src={`${BasePrintableURL}/${letter}/letters.svg`}
      />
      <img
        className={styles["card-image"]}
        src={`${BasePrintableURL}/${letter}/header.svg`}
      />
    </div>
    <div className={styles["card-bottom"]}>
      <div className={styles["card-download"]}>DOWNLOAD</div>
    </div>
  </a>
);

const Resources = () => {
  useEffect(() => {
    track("lit_web_page_load", { page: TRACKING_PAGE });
  }, []);

  return (
    <div>
      {/* HERO SECTION */}
      <section className={styles["hero-section"]}>
        <div className={styles["purple-content-container"]}>
          <div className={styles["hero-content"]}>
            <div className={styles["hero-text"]}>
              <h1 className={styles.title}>resources</h1>
              <p className={styles.subtitle}>
                Download free coloring and DIY printables to enhance your
                learner&apos;s experience offline!
              </p>
              <a
                className={cn(
                  getButtonClassName({ color: "macaw", variant: "solid" }),
                  styles["hero-button"],
                )}
                href="https://lit-lessons-cdn.duolingo.com/resources/printables/a-to-z/duolingo_abc_a-to-z_printable.pdf"
                onClick={() =>
                  track("lit_web_click", {
                    description: "all_letters",
                    page: TRACKING_PAGE,
                    target: "printable_activity",
                  })
                }
                rel="noreferrer"
                target="_blank"
              >
                DOWNLOAD ALL
              </a>
            </div>
            <img className={styles["hero-image"]} src={imageHero} />
          </div>
        </div>
        <div className={styles["bottom-border-container"]}>
          <img className={styles["border-image"]} src={imageSectionBottom} />
        </div>
      </section>
      {/* PRINTABLES SECTION */}
      <section className={styles["printables-section"]}>
        <div className={styles["printables-content"]}>
          <div className={styles.cards}>
            {Letters.map((letter, index) => (
              <PrintableCard key={index} letter={letter} />
            ))}
          </div>
        </div>
      </section>
      {/* FOOTER SECTION */}
      <section className={styles["footer-section"]}>
        <div className={styles["bottom-background"]}>
          <div className={styles["top-border-container"]}>
            <img className={styles["border-image"]} src={imageHillTop} />
          </div>
          <div className={styles.contact}>
            <h1 className={styles["contact-title"]}>contact us</h1>
            <p className={styles["contact-subtitle"]}>
              Reach out at{" "}
              <a
                className={styles["contact-link"]}
                href={`mailto:${Links.Email}`}
                onClick={() =>
                  track("lit_web_click", {
                    page: TRACKING_PAGE,
                    target: "feedback_email",
                  })
                }
              >
                {Links.Email}
              </a>{" "}
              or download our{" "}
              <a
                className={styles["contact-link"]}
                href={Links.PressKit}
                onClick={() =>
                  track("lit_web_click", {
                    page: TRACKING_PAGE,
                    target: "press_kit",
                  })
                }
              >
                Press Kit
              </a>
            </p>
          </div>
          <div className={styles.links}>
            <div className={styles.spacer} />
            <FooterLinks />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Resources;
