import "web-ui/styles/fonts.scss";

import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoadingDots } from "web-ui";

import Topbar from "components/common/Topbar";
import Admin from "components/pages/Admin";
import Assets from "components/pages/Admin/Assets";
import CourseData from "components/pages/Admin/CourseData";
import Home from "components/pages/Home";
import Resources from "components/pages/Resources";
import Teachers from "components/pages/Teachers";
import UserProvider from "contexts/User";

import "./App.module.scss";

const ExternalRedirect = ({ url }: { url: string }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <LoadingDots type="screen" />;
};

const deeplinks = ["/level/*", "show_level/*", "next_lesson", "teacher_zone"];

const App = () => {
  const topbarRef = React.useRef<HTMLDivElement>(null);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <UserProvider>
          <Topbar inputRef={topbarRef} />
          <Routes>
            <Route element={<Home topbarRef={topbarRef} />} path="/" />
            <Route element={<Teachers />} path="/how-we-teach" />
            <Route element={<Resources />} path="/resources" />
            <Route element={<Admin />} path="/admin">
              <Route element={<Assets />} path="assets" />
              <Route element={<CourseData />} path="course-data" />
            </Route>
            {deeplinks.map((path, index) => (
              <Route
                element={
                  <ExternalRedirect url="https://www.duolingo.com/abc" />
                }
                key={index}
                path={path}
              />
            ))}
            <Route
              element={
                <ExternalRedirect url="https://www.duolingo.com/cookies" />
              }
              path="/cookies"
            />
            <Route element={<Navigate replace={true} to="/" />} path="*" />
          </Routes>
        </UserProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default App;
