import imageQuote from "images/icon-quote.svg";

import styles from "./Testimonial.module.scss";

interface Person {
  image: string;
  name: string;
  title: TranslatedString;
}

interface Item {
  quote: TranslatedString;
  person?: Person;
}

const AnonymousTestimonial = (props: { quote: TranslatedString }) => (
  <div className={styles["anonymous-card"]}>
    <img className={styles["anonymous-image"]} src={imageQuote} />
    <div className={styles["anonymous-quote"]}>{props.quote}</div>
  </div>
);

const CreditedTestimonial = (props: {
  person: Person;
  quote: TranslatedString;
}) => (
  <section className={styles["credited-card"]}>
    <div className={styles["card-top"]}>
      <img className={styles["credited-image"]} src={props.person.image} />
      <p className={styles["credited-quote"]}>{props.quote}</p>
    </div>
    <div className={styles["card-bottom"]}>
      <p className={styles.name}>{props.person.name}</p>
      <p className={styles.title}>{props.person.title}</p>
    </div>
  </section>
);

const Testimonial = ({ item: { quote, person } }: { item: Item }) => {
  if (person) {
    return <CreditedTestimonial person={person} quote={quote} />;
  } else {
    return <AnonymousTestimonial quote={quote} />;
  }
};

export default Testimonial;
