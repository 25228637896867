import cn from "classnames";
import * as React from "react";
import InlineSVG from "react-inlinesvg";
import { getButtonClassName } from "web-ui";

import imageAppStore from "images/badge-app-store.svg";
import imagePlayStore from "images/badge-play-store.svg";
import { R } from "strings/definitions";
import { DesktopMinWidth, Links } from "util/constants";
import { useWindowDimensions } from "util/dimensions";
import { _i } from "util/i18n";
import track from "util/track";
import type { Page } from "util/track";

import styles from "./AppLinks.module.scss";

interface BaseButtonProps
  extends Pick<
    Props,
    "className" | "onClick" | "onDarkBackground" | "size" | "style"
  > {
  children: React.ReactNode;
  href: string;
  image: string;
}

const BaseButton = ({
  children,
  className,
  href,
  image,
  onClick,
  onDarkBackground,
  size = "default",
  style = "white",
}: BaseButtonProps) => (
  <a
    className={cn(
      getButtonClassName({
        variant: style === "blue" || onDarkBackground ? "solid" : "stroke",
      }),
      styles[
        `wrap${style === "blue" ? "-blue" : onDarkBackground ? "-white" : ""}`
      ],
      styles[`size-${size}`],
      className,
    )}
    href={href}
    onClick={onClick}
  >
    <InlineSVG className={styles.icon} src={image} />
    <span className={styles.text}>{children}</span>
  </a>
);

interface Props {
  className?: string;
  onClick?: () => void;
  onDarkBackground?: boolean;
  size?: "compact" | "default" | "icon";
  type: "apple" | "google";
  style?: "blue" | "white";
}

const MobileAppButton = ({ type, ...rest }: Props) => {
  switch (type) {
    case "apple":
      return (
        <BaseButton href={Links.IOS} image={imageAppStore} {...rest}>
          {_i(R.DOWNLOAD_ON_THE_APP_STORE)}
        </BaseButton>
      );
    case "google":
      return (
        <BaseButton href={Links.Android} image={imagePlayStore} {...rest}>
          {_i(R.GET_IT_ON_GOOGLE_PLAY)}
        </BaseButton>
      );
    default:
      return null;
  }
};

interface AppLinksProps {
  page: Page;
}

const AppLinks = ({ page }: AppLinksProps) => {
  const { width } = useWindowDimensions();

  return (
    <div className={styles["app-links-container"]}>
      <MobileAppButton
        onClick={() => track("lit_web_click", { page, target: "ios_download" })}
        size={width > DesktopMinWidth ? "default" : "compact"}
        style="blue"
        type="apple"
      />
      <MobileAppButton
        onClick={() =>
          track("lit_web_click", { page, target: "android_download" })
        }
        size={width > DesktopMinWidth ? "default" : "compact"}
        style="blue"
        type="google"
      />
    </div>
  );
};

export default AppLinks;
