import cn from "classnames";
import { useEffect } from "react";
import { getButtonClassName } from "web-ui";

import Accordion from "components/common/Accordion";
import AppLinks from "components/common/AppLinks";
import Carousel from "components/common/Carousel";
import FooterLinks from "components/common/FooterLinks";
import Testimonial from "components/common/Testimonial";
import imageEfficacy from "images/abc-efficacy.svg";
import imageCastReading from "images/cast-reading.svg";
import imageFluency from "images/curriculum-fluency.svg";
import imagePhonemicAwareness from "images/curriculum-phonemic-awareness.svg";
import imagePhonics from "images/curriculum-phonics.svg";
import imageReadingComprehension from "images/curriculum-reading-comprehension.svg";
import imageVocabulary from "images/curriculum-vocabulary.svg";
import imageHillTop from "images/hill-top-blue.svg";
import imageApple from "images/icon-apple.svg";
import imageBookStack from "images/icon-book-stack.svg";
import imageHeadphones from "images/icon-headphones.svg";
import imageHeartBook from "images/icon-heart-book.svg";
import imagePencil from "images/icon-pencil.svg";
import imageTrophy from "images/icon-trophy.svg";
import imageInverseSectionBottom from "images/inverse-section-bottom-blue.svg";
import imageMailIcon from "images/mail-icon.svg";
import imageSectionBottom from "images/section-bottom-blue.svg";
import imageSectionTop from "images/section-top-blue.svg";
import imageTeacherBarbie from "images/teacher-barbie.webp";
import imageFooter from "images/teacher-footer.svg";
import imageTeacherHeidi from "images/teacher-heidi.webp";
import imageHero from "images/teacher-hero.svg";
import imageTeacherStacy from "images/teacher-stacy.webp";
import { R } from "strings/definitions";
import { Links, ScopeDocument } from "util/constants";
import { _i, _iText } from "util/i18n";
import track from "util/track";

import styles from "./Teachers.module.scss";

const TRACKING_PAGE = "how_we_teach";

interface ApproachItem {
  description: TranslatedString;
  image: string;
  title: TranslatedString;
}

const ApproachCard = ({
  item: { image, title, description },
}: {
  item: ApproachItem;
}) => (
  <div className={styles.card}>
    <img className={styles["card-image"]} src={image} />
    <div className={styles["card-content"]}>
      <div className={styles["card-title"]}>{title}</div>
      <div className={styles["card-description"]}>{description}</div>
    </div>
  </div>
);

const APPROACH_CARDS = [
  {
    description: _i(R.INTERACTIVE_ACTIVITIES_THAT_MAXIMIZE_LEARNING),
    image: imagePencil,
    title: _i(R.LEARN_BY_DOING),
  },
  {
    description: _i(R.LESSONS_TAKE_5_MINUTES_OR_LESS_KEEPING_KIDS_MOTIVATED),
    image: imageApple,
    title: _i(R.BITESIZED_CHUNKS),
  },
  {
    description: _i(R.WITH_9_LEVELS_TO_START_FROM_KIDS_MOVE_AT_THEIR_OWN_PACE),
    image: imageBookStack,
    title: _i(R.DIFFERENTIATED_LEARNING),
  },
  {
    description: _i(R.OUR_GAMIFIED_APPROACH_FOSTERS_A_LOVE_OF_READING),
    image: imageHeartBook,
    title: _i(R.ENGAGE_THROUGH_PLAY),
  },
  {
    description: _i(R.SCAFFOLDED_LESSONS_BUILD_ON_WHAT_KIDS_KNOW),
    image: imageTrophy,
    title: _i(R.BUILD_KIDS_SKILLS),
  },
  {
    description: _i(R.KIDS_CAN_PLAY_AND_LEARN_ON_THEIR_OWN_NO_WIFI_REQUIRED),
    image: imageHeadphones,
    title: _i(R.INDEPENDENT_USE),
  },
];

interface CurriculumItem {
  description: TranslatedString;
  imageSrc: string;
}

const CurriculumInfo = ({
  item: { imageSrc, description },
}: {
  item: CurriculumItem;
}) => (
  <div className={styles["curriculum-item"]}>
    <div className={styles["curriculum-item-description"]}>{description}</div>
    <img className={styles["curriculum-item-image"]} src={imageSrc} />
  </div>
);

const CURRICULUM_INFO = [
  {
    description: _i(R.WE_USE_EXPLICIT_SYSTEMATIC_PHONICS_INSTRUCTION),
    imageSrc: imagePhonics,
    title: _iText(R.PHONICS_LOWERCASE),
  },
  {
    description: _i(R.WE_TEACH_KIDS_TO_RECOGNIZE_THE_INDIVIDUAL_SOUNDS),
    imageSrc: imagePhonemicAwareness,
    title: _iText(R.PHONEMIC_AWARENESS),
  },
  {
    description: _i(R.WE_TEACH_COMPREHENSION_THROUGH_INTERACTIVE_STORIES),
    imageSrc: imageReadingComprehension,
    title: _iText(R.READING_COMPREHENSION),
  },
  {
    description: _i(
      R.ABC_IS_DESIGNED_TO_BUILD_FLUENCY_SO_KIDS_CAN_READ_WITH_SPEED,
    ),
    imageSrc: imageFluency,
    title: _iText(R.FLUENCY),
  },
  {
    description: _i(R.WE_DEEPEN_AND_EXPAND_KIDS_VOCABULARY),
    imageSrc: imageVocabulary,
    title: _iText(R.VOCABULARY),
  },
];

const TESTIMONIALS = [
  {
    person: {
      image: imageTeacherStacy,
      name: "Stacy",
      title: _i(R.TECH_LIT_LOWER_ELEMENTARY),
    },
    quote: _i(R.EVERY_SINGLE_STUDENT_ENJOYS_IT),
  },
  {
    person: {
      image: imageTeacherBarbie,
      name: "Barbie",
      title: _i(R.PREK_TEACHER),
    },
    quote: _i(R.ABC_IS_SIMPLE_FOR_KIDS),
  },
  {
    person: {
      image: imageTeacherHeidi,
      name: "Heidi",
      title: _i(R.N2ND_GRADE_TEACHER),
    },
    quote: _i(R.KIDS_CAN_BE_SUCCESSFUL_WITH_ABC),
  },
];

const Teachers = () => {
  useEffect(() => {
    track("lit_web_page_load", { page: TRACKING_PAGE });
  }, []);

  return (
    <div>
      {/* HERO SECTION */}
      <section className={styles["colorful-section"]}>
        <div className={styles["blue-content-container"]}>
          <div className={styles["hero-content"]}>
            <div className={styles["hero-text"]}>
              <h1 className={styles.title}>{_i(R.HOW_WE_TEACH)}</h1>
              <p className={styles.subtitle}>
                {_i(
                  R.DUOLINGO_ABCS_APPROACH_TO_TEACHING_KIDS_HOW_TO_READ_IS_BUILT,
                )}
              </p>
              <AppLinks page={TRACKING_PAGE} />
            </div>
            <img className={styles["hero-image"]} src={imageHero} />
          </div>
        </div>
        <div className={styles["bottom-border-container"]}>
          <img
            className={styles["border-image"]}
            src={imageInverseSectionBottom}
          />
        </div>
      </section>
      {/* APPROACH SECTION */}
      <section className={styles["white-section"]}>
        <div className={styles["approach-content"]}>
          <div className={styles["approach-text"]}>
            <h1 className={styles.title}>{_i(R.THE_SCIENCE_BEHIND_ABC)}</h1>
            <p className={styles["subtitle-wolf"]}>
              {_i(R.OUR_PROGRAM_IS_BASED_ON_THE_SCIENCE_OF_READING)}
            </p>
          </div>
          <div className={styles["approach-cards"]}>
            {APPROACH_CARDS.map((item, index) => (
              <ApproachCard item={item} key={index} />
            ))}
          </div>
          <div className={styles["approach-text"]}>
            <a
              className={styles["efficacy-link"]}
              href="https://duolingo.com/efficacy"
              onClick={() =>
                track("lit_web_click", {
                  page: TRACKING_PAGE,
                  target: "efficacy_page",
                })
              }
            >
              {_i(R.LEARN_MORE_ABOUT_THE_DUOLINGO_METHOD)}
            </a>
          </div>
        </div>
      </section>
      {/* CURRICULUM SECTION */}
      <section className={styles["colorful-section"]}>
        <div className={styles["top-border-container"]}>
          <img className={styles["border-image"]} src={imageSectionTop} />
        </div>
        <div className={styles["blue-content-container"]}>
          <div className={styles["curriculum-content"]}>
            <div className={styles["curriculum-text"]}>
              <h1 className={styles.title}>{_i(R.OUR_CURRICULUM)}</h1>
              <p className={styles.subtitle}>
                {_i(R.WE_TEACH_THE_FIVE_COMPONENTS_OF_LITERACY)}
              </p>
            </div>
            <Accordion
              accordionData={CURRICULUM_INFO.map((item, index) => ({
                content: <CurriculumInfo item={item} key={index} />,
                title: item.title,
              }))}
              className={styles["curriculum-accordion"]}
              startingOpenFoldIndices={[0, 1, 2, 3, 4]}
            />
            <div className={styles["curriculum-text"]}>
              <p className={styles.subtitle}>
                {_i(R.FOR_A_DEEPER_LOOK_AT_OUR_CURRICULUM, {
                  link_class: styles["standards-link"],
                  standards_link:
                    "https://lit-lessons-cdn.duolingo.com/resources/duolingo_abc_common_core_alignment.pdf",
                })}
              </p>
            </div>
            <a
              className={cn(
                getButtonClassName({ color: "owl", variant: "solid" }),
                styles["curriculum-button"],
              )}
              href={ScopeDocument}
              onClick={() =>
                track("lit_web_click", {
                  page: TRACKING_PAGE,
                  target: "scope_and_sequence_doc",
                })
              }
              rel="noreferrer"
              target="_blank"
            >
              {_i(R.SCOPE_AND_SEQUENCE)}
            </a>
          </div>
        </div>
        <div className={styles["bottom-border-container"]}>
          <img className={styles["border-image"]} src={imageSectionBottom} />
        </div>
      </section>
      {/* EFFICACY SECTION */}
      <section className={styles["white-section"]}>
        <div className={styles["efficacy-content-top"]}>
          <div className={styles["efficacy-text-right"]}>
            <h1 className={styles.title}>{_i(R.EFFECTIVE_SCREEN_TIME)}</h1>
            <p className={styles["subtitle-wolf"]}>
              {_i(
                R.AFTER_2_MONTHS_OF_DUOLINGO_ABC_KIDS_SHOW_IMPROVEMENTS_IN_LIT,
              )}
            </p>
            <a
              className={styles["efficacy-link"]}
              href="https://duolingo-papers.s3.amazonaws.com/reports/edc-report-on-duolingo-abc.pdf"
              onClick={() =>
                track("lit_web_click", {
                  page: TRACKING_PAGE,
                  target: "efficacy_report",
                })
              }
              rel="noreferrer"
              target="_blank"
            >
              {_i(R.READ_THE_FULL_REPORT)}
            </a>
          </div>
          <img className={styles["efficacy-image"]} src={imageEfficacy} />
        </div>
        <div className={styles["efficacy-content-bottom"]}>
          <div className={styles["efficacy-text-left"]}>
            <h1 className={styles.title}>
              {_i(R.STORIES_THAT_TEACH_AND_DELIGHT)}
            </h1>
            <p className={styles["subtitle-wolf"]}>
              {_i(R.KIDS_COME_TO_KNOW_AND_LOVE_OUR_CHARACTERS)}
            </p>
          </div>
          <img className={styles["efficacy-image"]} src={imageCastReading} />
        </div>
      </section>
      {/* TESTIMONIALS SECTION */}
      <section className={styles["colorful-section"]}>
        <div className={styles["top-border-container"]}>
          <img className={styles["border-image"]} src={imageSectionTop} />
        </div>
        <div className={styles["blue-content-container"]}>
          <div className={styles["testimonials-content"]}>
            <h1 className={styles["testimonials-title"]}>
              {_i(R.WHY_TEACHERS_CHOOSE_ABC)}
            </h1>
            <div className={styles["testimonials-cards"]}>
              {TESTIMONIALS.map((teacher, index) => (
                <Testimonial item={teacher} key={index} />
              ))}
            </div>
            <Carousel
              className={styles["testimonials-carousel"]}
              dotsClassName={styles.dots}
              items={TESTIMONIALS.length}
            >
              {index => <Testimonial item={TESTIMONIALS[index]} />}
            </Carousel>
          </div>
        </div>
        <div className={styles["bottom-border-container"]}>
          <img className={styles["border-image"]} src={imageSectionBottom} />
        </div>
      </section>
      {/* FOOTER SECTION */}
      <section className={styles["footer-section"]}>
        <div className={styles["footer-content"]}>
          <div className={styles["footer-text"]}>
            <h1 className={styles.title}>{_i(R.BRING_ABC_TO_YOUR_LEARNERS)}</h1>
            <p className={styles["subtitle-wolf"]}>
              {_i(R.DOWNLOAD_THE_APP_TODAY, {
                group_link: Links.TeacherFacebook,
                link_class: styles["footer-subtitle-link"],
              })}
            </p>
            <AppLinks page={TRACKING_PAGE} />
          </div>
          <img className={styles["footer-image"]} src={imageFooter} />
        </div>
        <div className={styles["footer-bottom-background"]}>
          <div className={styles["top-border-container"]}>
            <img className={styles["border-image"]} src={imageHillTop} />
          </div>
          <div className={styles.questions}>
            <h1 className={styles["questions-title"]}>{_i(R.QUESTIONS)}</h1>
            <p className={styles["questions-subtitle"]}>
              {_i(R.GET_HELP_OR_SHARE_FEEDBACK_WITH_OUR_TEACHER_SUPPORT_TEAM)}
            </p>
            <a
              className={cn(
                getButtonClassName({ color: "macaw", variant: "solid" }),
                styles["questions-button"],
              )}
              href={`mailto:${Links.Email}?subject=Teacher%20%2D%20Duolingo%20ABC%20Feedback`}
              onClick={() =>
                track("lit_web_click", {
                  page: TRACKING_PAGE,
                  target: "feedback_email",
                })
              }
            >
              <img
                className={styles["questions-button-icon"]}
                src={imageMailIcon}
              />
              {_i(R.CONTACT_US_CAPITALIZED)}
            </a>
          </div>
          <div className={styles.links}>
            <div className={styles.spacer} />
            <FooterLinks />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Teachers;
